import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import LazyHydrate from 'react-lazy-hydration';
import styled from 'styled-components';
import { HomePagesContext } from 'helpers/providers/contexts';

const App = () => {
  const { funnelLogo, funnelBackgroundImage, funnelBackgroundColor } =
    useContext(HomePagesContext);

  return (
    <>
      <Helmet>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      <LazyHydrate ssrOnly>
        <Wrapper
          $funnelBackgroundColor={funnelBackgroundColor}
          $funnelBackgroundImage={funnelBackgroundImage}
        >
          <Img src={funnelLogo} />
        </Wrapper>
      </LazyHydrate>
    </>
  );
};

const Wrapper = styled.div`
  background-image: ${({ $funnelBackgroundImage }) =>
    `url(${$funnelBackgroundImage})`};
  background-color: ${({ $funnelBackgroundColor }) => $funnelBackgroundColor};
  background-position: center, center;
  background-size: cover, cover;
  background-repeat: no-repeat, no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const Img = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`;

export default App;
